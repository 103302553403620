import { Toast } from 'vant';
import storage from 'store';
/**
 * 工具对象
 * @type {{setRem(): void}}
 */
const utils = {

  /**
   * 设置rem
   */
  setRem () {
    // let d = window.document.createElement('div');
    // d.style.width = '1rem';
    // d.style.display = 'none';
    // let head = window.document.getElementsByTagName('head')[0];
    // head.appendChild(d);
    // let defaultFontSize = parseFloat(window.getComputedStyle(d, null).getPropertyValue('width')); // 系统当前字体大小
  // * (16 / defaultFontSize)
    const htmlElem = document.querySelector('html');
    let htmlWidth = htmlElem.clientWidth;
    let width = htmlWidth > 500 ? 500 : htmlWidth;
    const ratio = width / 10
    // htmlElem.style.width = width + 'px';
    htmlElem.style.fontSize = (ratio) + 'px';

    utils.ratio = ratio / 75;
    return htmlWidth;
  },
  storage,
  /* 比例*/
  ratio: 0,

  reg: {
    user: /^\w{1,15}$/,
    phone: /^1[3-9]\d{9}$/, // phone手机号
    email: /^[\w]+@[\w]+(\.[\w]+)+$/, // email邮箱
    id: /^[1-9]\d{5}([12]\d)?(\d{2})((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2,3}[0-9Xx]$/, // id身份证
    dateYMD: /([0-9]{4}-[0-9]{2}-[0-9]{2})/, // dateYMD匹配yy-MM-dd格式时间
    num: /^\d+$/, // num数字
    space: /\s*/g, // 空格
    currency: /^(([1-9]\d*)|\d)([.]\d{1,2})?$/, // 货币
    expression: /[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF][\u200D|\uFE0F]|[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF]|[0-9|*|#]\uFE0F\u20E3|[0-9|#]\u20E3|[\u203C-\u3299]\uFE0F\u200D|[\u203C-\u3299]\uFE0F|[\u2122-\u2B55]|\u303D|\uA9|\uAE|\u3030/ig, // eslint-disable-line
    hanzi: /[\u4E00-\u9FA5]/g // 汉字
  },

  toast (params, defaultMessage, type) {
    let message = params;
    if (params.constructor === Object) {
      message = params.message
    } else {
      params = {};
    }
    message = message || defaultMessage || ''
    Toast({
      message: message,
      type: type || '',
      duration: 1500,
      ...params
    });
  },

  successToast (params) {
    utils.toast(params, '成功', 'success')
  },

  failToast (params) {
    utils.toast(params, '失败', 'fail')
  },

  loadingToast (params) {
  // , callback, mask = true
    let message = params;
    if (params.constructor === Object) {
      message = params.message
    } else {
      params = {
        mask: true
      };
    }
    params.message = message;
    params.duration = params.duration || 0;
    utils.toast(params, 'ローディング...', 'loading')
  },

  clearToast () {
    Toast.clear();
  },

  /**
   * 去空格
   * @param str 字符串
   * */
  trim (str) {
    let trimStr = '';

    trimStr = str.replace(/\s/g, '');

    return trimStr;
  },

  /**
   * 返回token
   * @returns {String}
   * */
  getToken () {
    return (storage.get('loginToken') || {}).accessToken || '';
  },

  /* 把一组图片转化为字符串，元素格式为{src:'',name:''},返回字符串*/
  formatImages (photos) {
    let ReturnPhotos = null;

    if (photos.length) {
      ReturnPhotos = photos.map((item) => item.name);
      ReturnPhotos = ReturnPhotos.join(',');
    }

    return ReturnPhotos;

  },

  /* 格式化时间*/
  formatDate (time, fmt) {
    try {
      time = time || '';
      if (typeof time === 'string') {
        time = time.replace(/-/g, '/');
      }
      if (!time) {
        return '';
      }
      let date = new Date(time);
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
      }
      let o = {
        'M+': date.getMonth() + 1,
        'd+': date.getDate(),
        'h+': date.getHours(),
        'm+': date.getMinutes(),
        's+': date.getSeconds()
      };
      for (let k in o) {
        if (new RegExp(`(${k})`).test(fmt)) {
          let str = o[k] + '';
          fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : padLeftZero(str));
        }
      }
      return fmt;
    } catch (e) {
      return '';
    }

    function padLeftZero (str) {
      return ('00' + str).substr(str.length);
    }
  },

  /* 给不足两位的数值补零*/
  repairZero (v) {
    const value = v.toString();

    return value[1] ? value : 0 + value;
  },

  /**
   * 格式化数字，两位小数
   * @param v 数字
   * @param length 小数后几位，默认2
   * @returns 返回格式化的数字
   */
  toFixed (v, length) {
    v = v || 0;

    v = Number(v);
    let temp = Math.pow(10, length);
    let value = (this.div(parseInt(this.mul(v, temp)), temp)).toFixed(length);

    return value;
  },

  /*
  * 通过年和月返回这个月或下个月或上个月的天数
  * params: year年，month月，mode模式，0为这个月、1上个月、2为下个月吗，默认为0
  * return year年 month月 day 天数, thisDate当前的时间
  * */
  computedDay (time) {

    /* 当前的时间*/
    const date = new Date(time);
    const date1 = new Date();
    let obj = {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      nowDay: {
        year: date1.getFullYear(),
        month: date1.getMonth() + 1,
        day: date1.getDate()
      }
    };
    obj.firstWeek = (new Date(`${obj.year}/${obj.month}/1`)).getDay() || 7;
    obj.allDay = this.monthToDay(obj.year, obj.month);
    return obj;
  },

  /*
  * 通过年和月返回这个月的天数
  * params:year年，month月
  * return day   天数
  * */
  monthToDay (year, month) {
    let day = 0;

    /* 将参数转换为number*/
    month *= 1;
    year *= 1;

    switch (month * 1) {
      case 2:
        if ((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0) {
          day = 29;
        } else {
          day = 28;
        }
        break;
      case 4:
      case 6:
      case 9:
      case 11:
        day = 30;
        break;
      default:
        day = 31;
    }
    return day;
  },

  /**
   *   颜色渐变
   *   num：颜色个数
   */
  fColorGradualChange (startColor, endColor, num) {
    let rgb = /^rgb|RGB\((([0-9]|[1-9]\d|1\d\d|2([0-4]\d|5[0-5])),){2}([0-9]|[1-9]\d|1\d\d|2([0-4]\d|5[0-5]))\)$/; // rgb
    let hex = /(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/i; // 16进制
    let { fAnalysisRGB, fColorToHex } = this;
    // 颜色预处理
    let startRGB, endRGB;
    if (hex.test(startColor)) {
      startRGB = fAnalysisRGB(startColor);
    } else if (rgb.test(startColor)) {
      startRGB = startColor.substring(3, 15).split(',');
    }
    if (hex.test(endColor)) {
      endRGB = fAnalysisRGB(endColor);
    } else if (rgb.test(startColor)) {
      endRGB = endColor.substring(3, 15).split(',');
    }
    let startR = startRGB[0]; let startG = startRGB[1]; let startB = startRGB[2];
    let endR = endRGB[0]; let endG = endRGB[1]; let endB = endRGB[2];
    let sR = (endR - startR) / num;
    let sG = (endG - startG) / num;
    let sB = (endB - startB) / num;
    let colors = [];
    for (let i = 0; i < num; i++) {
      colors.push(fColorToHex(parseInt((sR * i + startR)), parseInt((sG * i + startG)), parseInt((sB * i + startB))));
    }
    return colors;
  },

  /**
   *   解析rgb格式
   */
  fAnalysisRGB (temp) {
    temp = temp.toLowerCase().substring(1, temp.length);
    let colors = [];
    colors.push(parseInt(temp.substring(0, 2), 16));
    colors.push(parseInt(temp.substring(2, 4), 16));
    colors.push(parseInt(temp.substring(4, 6), 16));
    return colors;
  },

  /**
   *   rgb转hex
   */
  fColorToHex (r, g, b) {
    let { fAddZero } = utils;
    return '#' + fAddZero(r.toString(16)) + fAddZero(g.toString(16)) + fAddZero(b.toString(16));
  },

  /**
   *   加0补位
   */
  fAddZero (v) {
    let newv = '00' + v;
    return newv.substring(newv.length - 2, newv.length);
  },

  /* 验证手机号格式*/
  verifyMobile (mobile, areaCode) {
    if (!mobile) {
      utils.toast('请输入您的手机号码!');

      return false;
    }
    if (!/^1[3-9]\d{9}$/.test(mobile)) {
      utils.toast('该手机号码不正确，请重新输入!');
      return false;
    }
    return true;

  },
  verifyEmail (email) {
    if (!email) {
      utils.toast('メールアドレスを入力してください');
      return false;
    }

    if (!/^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/.test(email)) {
      utils.toast('メールボックスのフォーマットが間違っています');

      return false;
    }

    return true;

  },

  /* 非空验证*/
  verifyEmpty (v, message) {
    if (typeof (v) === 'string') {
      v = v.replace(/\s/g, '');
    }
    if (!v) {
      utils.toast(message);
      return false;
    }

    return true;
  },

  /* 验证长度是否在给定范围内符合要求*/
  verifyLengeRange (v, message, min, max) {
    min = min || v.length;
    max = max || v.length;

    if (v.length < min || v.length > max) {
      utils.toast(message);

      return false;
    }

    return true;
  },

  searchURL: (function () {// 获取路径参数
    let obj = {};
    let data = location.search;
    let arr = [];
    if (data) {
      arr = data.substr(1).split('&');
      for (let i = 0; i < arr.length; i++) {
        let arr1 = arr[i].split('=');
        obj[arr1[0]] = decodeURIComponent(arr1[1]);
      }
      return obj;
    }
    return '';
  })(),
  setURL (url, obj) {// 设置路径参数
    let arr = [];
    for (let key in obj) {
      arr.push(key + '=' + obj[key]);
    }

    return url + '?' + arr.join('&');
  },
  isPc() {
    let str = window.navigator.userAgent;
    str = str.toLowerCase();
    return str.indexOf('mobile') === -1 && // 不是手机
    (str.indexOf('windows') > -1 || // 是window
    str.indexOf('mac') > -1); // 是mac
  },
  deepCopy (data) {
    return JSON.parse(JSON.stringify(data || {}));
  },

  add (a, b) {
    if (!a) {a = 0;}
    if (!b) {b = 0;}
    let c, d, e;
    try {
      c = a.toString().split('.')[1].length;
    } catch (f) {
      c = 0;
    }
    try {
      d = b.toString().split('.')[1].length;
    } catch (f) {
      d = 0;
    }
    e = Math.pow(10, Math.max(c, d));
    return (this.mul(a, e) + this.mul(b, e)) / e;
  },

  sub (a, b) {
    if (!a) {a = 0;}
    if (!b) {b = 0;}
    let c, d, e;
    try {
      c = a.toString().split('.')[1].length;
    } catch (f) {
      c = 0;
    }
    try {
      d = b.toString().split('.')[1].length;
    } catch (f) {
      d = 0;
    }
    e = Math.pow(10, Math.max(c, d));
    return (this.mul(a, e) - this.mul(b, e)) / e;
  },

  mul (a, b) {
    if (!a) {a = 0;}
    if (!b) {b = 0;}
    let c = 0;
    let d = a.toString();
    let e = b.toString();
    try {
      c += d.split('.')[1].length;
    } catch (f) {
      console.log(f);
    }
    try {
      c += e.split('.')[1].length;
    } catch (f) {
      console.log(f);
    }
    return Number(d.replace('.', '')) * Number(e.replace('.', '')) / Math.pow(10, c);
  },

  div (a, b) {
    let c; let d; let e = 0;
    let f = 0;
    try {
      e = a.toString().split('.')[1].length;
    } catch (g) {
      console.log(g);
    }
    try {
      f = b.toString().split('.')[1].length;
    } catch (g) {
      console.log(g);
    }

    c = Number(a.toString().replace('.', ''));
    d = Number(b.toString().replace('.', ''));
    return this.mul(c / d, Math.pow(10, f - e));
  }
};


export default utils;
