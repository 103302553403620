<template>
  <div id="app"
    ref="app"
    :style="{background: meta.bg || '#232324'}"
    :class="{'app-padTop':!meta.topHide}"
  >
    <template v-if="show">
      <div class="topFixed" v-show="!meta.logo">
        <van-image @click="$router.push({name:'Home'})" class="imgFixed" :src="require('@/assets/images/logo.png')"/>
      </div>
      <div class="top-nav-bar" v-show="!meta.topHide" :class="{'has-shadow':!meta.hasShadow}">
        <van-nav-bar :title="meta.title" @click-left="$router.back()">
          <van-icon v-if="!meta.backHide" class="back-arrow" :style="{ color: meta.arrowColor || '#fff' }" name="arrow-left"  slot="left" />
        </van-nav-bar>
      </div>
      <div class="router-container" v-scroll="onScroll">
        <!--<router-view v-if="!meta.keepAlive" />-->
        <keep-alive :include="keepAliveInclude">
          <router-view :key="$route.name" />
        </keep-alive>
      </div>
      <van-tabbar route v-if="meta.showTabbar" >
        <van-tabbar-item v-for="(item,index) in tabbar" replace :to="item.url" :key="index">
          <template #icon="props">
            <img :src="props.active ? item.selected : item.select" />
          </template>
        </van-tabbar-item>
      </van-tabbar>
      <div v-if="meta.showTabbar" class="tabbar-placehold"></div>
      <div v-if="!meta.iphoneXHide" class="iphoneX"></div>
      <router-link v-if="meta.showfixedNav" :to="{name: 'CouponsIndex'}" class="fixed-nav icon"></router-link>
    </template>
     <router-view v-else :key="$route.name" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { Tabbar, TabbarItem, NavBar } from 'vant';
export default {
  name: 'App',
  components: {
    'van-tabbar': Tabbar,
    'van-tabbar-item': TabbarItem,
    'van-nav-bar': NavBar
  },
  data () {
    return {
      show: false,
      meta: {
        bg: '',
        topHide: '',
        backHide: '',
        title: '',
        logo: '',
        hasShadow: '',
        showTabbar: '',
        iphoneXHide: ''
      },
      tabbar: [
        {
          url: '/home',
          select: require('@/assets/images/icon_tab_01.png'),
          selected: require('@/assets/images/icon_tab_02.png')
        },
        {
          url: '/category',
          select: require('@/assets/images/icon_tab_03.png'),
          selected: require('@/assets/images/icon_tab_04.png')
        },
        {
          url: '/personal',
          select: require('@/assets/images/icon_tab_05.png'),
          selected: require('@/assets/images/icon_tab_06.png')
        }
      ],
      history: [],
      config: {
        uiUrl: 'https://lanhuapp.com/web/#/item/project/stage?pid=555fe0cf-2af1-4bea-90ae-c3033df4be63&image_id=38e4db42-6c53-49c4-8f48-4d77ce14e9ea',
        vueSwiper: 'https://swiperjs.com/vue',
        vueCanvasPosterApi: 'https://sunniejs.github.io/vue-canvas-poster/#/README'
      }
    };
  },
  computed: {
    ...mapGetters(['keepAliveInclude'])
  },
  created () {
    // 浏览器判断
    /* let ua = navigator.userAgent.toLowerCase() || '';
    this.setUa({
      isAlipay: ua.indexOf('alipayclient') !== -1, // 判断是否是支付宝
      isWeixin: ua.indexOf('micromessenger') !== -1// 判断是否是微信
    });
    this.$http.get({
      url: 'area/json',
      hideErrorToast: true
    }).then((res) => {
      let data = res.data;
      let obj = { province_list: {}, city_list: {}, county_list: {} };
      for (let i = 0; i < data.length; i++) {
        let item1 = data[i];
        obj.province_list[item1.provinceCode] = item1.name;
        for (let j = 0; j < item1.children.length; j++) {
          let item2 = item1.children[j];
          obj.city_list[item2.cityCode] = item2.name;
          for (let k = 0; k < item2.children.length; k++) {
            let item3 = item2.children[k];
            obj.county_list[item3.areaCode] = item3.name;
          }
        }
      }
      this.setArea(obj);
      this.setOriginalArea(data);
      // console.log(obj)
    // });*/
    // this.$http.get('discount/drawDiscount');
  },
  mounted () {
    const { $route: { meta }, $utils } = this;
    const isPc = $utils.isPc();
    isPc && (meta.bg = '#fff');
    this.meta = meta;
    this.show = !isPc;
  },
  watch: {
    $route (to, from) {
      this.meta = to.meta;
    }
  },
  methods: {
    ...mapActions([
      'setUa',
      'setArea',
      'setOriginalArea',
      'setAppScrollTop'
    ]),
    onScroll (event) {
      this.setAppScrollTop(event.scrollTop);
    }
  }
};
</script>

<style lang="less" scoped>
.topFixed{
  padding: 22px 0;
  display: flex;
  position: relative;
  z-index: 9999;
  justify-content: center;
  background-color: rgb(35, 35, 36);
  border-bottom: 1px solid rgba(255, 255, 255, .2);
  .imgFixed{
    width: 224px;
  }
}
.router-container {
  height: 100%;
  overflow-y: auto;
}
.tabbar-placehold {
  flex-shrink: 0;
  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 98px;
  }
}

</style>
