import 'babel-polyfill';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import utils from './assets/js/utils';
import './assets/images';
import { VueAxios } from './assets/js/request';
import VueAwesomeSwiper from 'vue-awesome-swiper';
import Swiper2, { Autoplay } from 'swiper';
import 'swiper/swiper.less';
import './assets/less/global.less';
import './permission';

import './assets/js/addGlobalCom';
import './assets/js/directives';
import './assets/js/filter';
import { loadElepay } from 'elepay-js-sdk';


import {
  Lazyload,
  Icon,
  Loading,
  List,
  Image as VanImage,
  Toast,
  Popup,
  Dialog,
  Col,
  Row,
  Button,
  Checkbox,
  CheckboxGroup
} from 'vant';
Swiper2.use([Autoplay]);
Vue.use(VueAxios).use(VueAwesomeSwiper).use(Lazyload, {
  // loading: LoadBgUrl,
  preload: 100,
  // error: ErrorBgUrl,
  attempt: 1
}).use(Icon).use(Loading).use(List).use(VanImage).use(Toast).use(Popup).use(Dialog).use(Col).use(Row).use(Button).use(Checkbox).use(CheckboxGroup);


let timer = null;
store.dispatch('setHtmlWidth', utils.setRem());
window.onresize = function () {
  clearTimeout(timer);
  timer = setTimeout(() => {
    store.dispatch('setHtmlWidth', utils.setRem());
  }, 300);
};
Vue.prototype.$utils = utils;
Vue.prototype.loadElepay = loadElepay;
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app');
