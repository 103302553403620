<template>
    <div id="listItemComp">
      <div class="thumb">
        <van-image class="img" :src="detail.coverAcrossTemp" fit="cover"></van-image>
      </div>
      <div class="content">
        <div class="top">
          <div class="name">{{ detail.name }}</div>
          <div class="tag-list">
            <div class="item">{{ detail.categoryName }}</div>
          </div>
        </div>
        <div class="bottom">
          <div class="left">
            <i class="icon icon-eyes"></i>
            <span>{{ detail.sum }}</span>
          </div>
          <div class="right">
            <slot name="right"></slot>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name: 'listItemComp',
  components: {},
  props: {
    detail: {
      type: Object,
      default () {
        return {};
      }
    }
  },
  data () {
    return {
    };
  },
  created () {
  },
  mounted () {

  },
  watch: {},
  methods: {}
};
</script>

<style scoped lang="less">
#listItemComp {
  display: flex;

  .thumb {
    flex-shrink: 0;
    width: 240px;
    height: 168px;
    margin-right: 24px;
    overflow: hidden;
    border-radius: 12px;

    .img {
      width: 100%;
      height: 100%;
    }
  }

  .content {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;

    .top {
      .name {
        margin-bottom: 12px;
        font-size: 26px;
        line-height: 42px;
        .multi-line-omit();
      }

      .tag-list {
        display: flex;
        flex-wrap: wrap;
        margin: -6px;

        .item {
          min-width: 120px;
          height: 36px;
          margin: 6px;
          font-size: 20px;
          line-height: 36px;
          color: rgba(255, 255, 255, 0.8);
          text-align: center;
          background-color: rgba(255, 255, 255, 0.1);
          border-radius: 6px;
        }
      }
    }

    .bottom {
      &,
      .left {
        display: flex;
        align-items: center;
      }

      .left {
        flex: 1;
        margin-right: 16px;
        font-size: 22px;
        line-height: 30px;
        color: rgba(255, 255, 255, 0.8);

        .icon-eyes {
          width: 24px;
          height: 24px;
          margin-right: 8px;
          background-image: url("~@/assets/images/icon_eyesOpen_02.png");
        }
      }

      .right {
        flex-shrink: 0;
      }
    }
  }
}
</style>