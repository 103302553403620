<template>
    <div id="vrVideoComp">
      <div class="loading" v-if="isLoading">
        <van-loading color="#fff" />
      </div>
      <slot name="other"></slot>
    </div>
</template>

<script>
import WebVRPolyfill from 'webvr-polyfill';
import * as THREE from 'three';
import { XRControllerModelFactory } from 'three/examples/jsm/webxr/XRControllerModelFactory.js';
import Hls from 'hls.js';
import { VR, AVR } from '@/assets/js/vr/mxreality';
import { mapGetters } from 'vuex';
AVR.playerIcon.gyroSvg = '<svg xmlns="http://www.w3.org/2000/svg" style="width:22px;height:16px;font-weight: bold" aria-hidden="true"><use  xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon_hand_01"/></svg>';
AVR.playerIcon.vrSvg = '<svg xmlns="http://www.w3.org/2000/svg" style="width:24px;height:14px;font-weight: bold" aria-hidden="true"><use  xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon_vr_01"/></svg>';
AVR.playerIcon.exit = require('@/assets/images/icon_close_02.png');
window.polyfill = new WebVRPolyfill();
window.THREE = THREE;
window.Hls = Hls;
export default {
  name: 'VRVideo',
  components: {},
  props: {
    src: {
      type: String,
      default: ''
    },
    playCfg: {
      type: Object,
      default () {
        // isHemisphere Boolean是否为半球
        return {};
      }
    }
  },
  data () {
    return {
      threeInfo: {
        renderer: null, // 渲染器
        scene: null, // 场景
        container: null // 容器
      },
      vr: null,
      isLoading: false
    };
  },
  computed: {
    ...mapGetters(['htmlWidth'])
  },
  mounted () {
    this.setVideo();
  },
  beforeDestroy () {
    this.destroy();
  },
  watch: {
    src () {
      this.setVideo();
    }
  },
  methods: {
    async init () {
      this.destroy();
      let renderer;
      AVR.debug = true;
      if (!AVR.Broswer.isIE() && AVR.Broswer.webglAvailable()) {
        // { antialias: true, alpha: true }
        renderer = new THREE.WebGLRenderer({
          antialias: true,
          alpha: true
        });
        renderer.xr.enabled = true;
        renderer.xr.setReferenceSpaceType('local');
      } else {
        renderer = new THREE.CanvasRenderer();
      }
      const container = document.getElementById('vrVideoComp');
      const scene = new THREE.Scene();
      this.threeInfo = {
        renderer,
        scene,
        container
      };
      // fov 选项可调整初始视频远近
      console.log(nowVR + 'nowVR');

      const nowVR = new VR(scene, renderer, container, { fov: 90, far: 2000 });
      nowVR.onLoadingChange = (status) => {
        this.isLoading = !nowVR.video.paused && status;
      };
      nowVR.operationCallback = this.operationCallback;
      nowVR.onVideoEvent = this.onVideoEvent;
      nowVR.loadProgressManager.onLoad = function () {
        // 视频静音
        // nowVR.video.muted = true;
        nowVR.bindVolumeBar();
      };
      // 光线
      const light = new THREE.DirectionalLight(0xffffff);
      scene.add(light);

      /* -------------------控制器显示--------------------*/
      const { buildController } = this;
      const controller = renderer.xr.getController(0);
      controller.addEventListener('connected', function (event) {
        this.add(buildController(event.data));
      });
      controller.addEventListener('disconnected', function () {
        this.remove(this.children[0]);
      });
      scene.add(controller);
      const controllerModelFactory = new XRControllerModelFactory();
      const controllerGrip = renderer.xr.getControllerGrip(0);
      controllerGrip.add(controllerModelFactory.createControllerModel(controllerGrip));

      renderer.setSize(window.innerWidth, window.innerHeight);
      container.appendChild(renderer.domElement);

      /**
           * 光线投射用于进行鼠标拾取（在三维空间中计算出鼠标移过了什么物体）
           * Raycaster( origin : Vector3, direction : Vector3, near : Float, far : Float )
           * origin —— 光线投射的原点向量。
           * direction —— 向射线提供方向的方向向量，应当被标准化。
           * near —— 返回的所有结果比near远。near不能为负值，其默认值为0。
           * far —— 返回的所有结果都比far近。far不能小于near，其默认值为Infinity（正无穷。）
           *
           */
      const raycaster = new THREE.Raycaster();
      const tempMatrix = new THREE.Matrix4();
      const clock = new THREE.Clock();
      let direction = {};
      let clockAdd = 0;
      // const ssaaParams = {
      //   sampleLevel: 2,
      //   renderToScreen: true,
      //   unbiased: true
      // };
      // const composer = new EffectComposer(renderer);
      // const renderPass = new RenderPass(scene, nowVR.camera);
      // composer.addPass(renderPass);
      // const ssaaRenderPass = new SSAARenderPass(scene, nowVR.camera);
      // composer.addPass(ssaaRenderPass);
      // const copyPass = new ShaderPass(CopyShader);
      // composer.addPass(copyPass);
      // ssaaRenderPass.sampleLevel = ssaaParams.sampleLevel;
      // ssaaRenderPass.unbiased = ssaaParams.unbiased;
      // ssaaRenderPass.enabled = true;
      // copyPass.enabled = false;
      let oldVisible = null;
      await nowVR.init(() => {
        const visible = nowVR.xrBtnGroup && nowVR.xrBtnGroup.visible || false;
        if (controller.children.length && oldVisible !== visible) {
          oldVisible = visible;
          controller.children.forEach((item) => {
            item.visible = visible;
          });
        }
        // const width = container.offsetWidth;
        // const height = container.offsetHeight;
        // composer.setPixelRatio(renderer.getPixelRatio());
        // composer.setSize(width, height);
        // composer.render(scene, nowVR.camera);
        if (nowVR.XR && visible && clockAdd > 0.3) {
          tempMatrix.identity().extractRotation(controller.matrixWorld);
          raycaster.ray.origin.setFromMatrixPosition(controller.matrixWorld);
          raycaster.ray.direction.set(0, 0, -1).applyMatrix4(tempMatrix);
          const { x, y, z } = raycaster.ray.direction;
          if (!(direction.x === x && direction.y === y && direction.z === z)) {
            direction = {
              x,
              y,
              z
            };
            const intersects = raycaster.intersectObject(nowVR.xrBtnGroup, true);
            if (intersects.length > 0) {

              /** intersect
                   * distance —— 射线投射原点和相交部分之间的距离。
                   * point —— 相交部分的点（世界坐标）
                   * face —— 相交的面
                   * faceIndex —— 相交的面的索引
                   * object —— 相交的物体
                   * uv —— 相交部分的点的UV坐标。
                   * uv2 —— 交点处的第二组UV坐标
                   * instanceId – 光线与InstancedMesh相交的实例的索引号
                   * */
              const res = intersects.filter(function (res) {
                return res && res.object && res.object.visible && !res.object.isNoIntersect;
              })[0];
              const currentObject = res.object;
              if (nowVR.selectedXRBtn !== currentObject) {
                if (nowVR.selectedXRBtn) {
                  nowVR.selectedXRBtn.material.opacity = nowVR.selectedXRBtn.currentOpacity;
                  // nowVR.selectedXRBtn.material.color.setHex(nowVR.selectedXRBtn.currentHex);
                }
                if (currentObject.name) {
                  nowVR.selectedXRBtn = currentObject;
                  nowVR.selectedXRBtn.currentPoint = res.point; // 相交部分的点（世界坐标）
                  nowVR.selectedXRBtn.currentOpacity = nowVR.selectedXRBtn.material.opacity;
                  nowVR.selectedXRBtn.material.opacity = 1;
                } else {
                  nowVR.selectedXRBtn = null;
                }
                // nowVR.selectedXRBtn.currentHex = nowVR.selectedXRBtn.material.color.getHex();
                // nowVR.selectedXRBtn.material.color.setHex(0xff0000);
              }
            } else if (nowVR.selectedXRBtn) {
              // nowVR.selectedXRBtn.material.color.setHex(nowVR.selectedXRBtn.currentHex);
              nowVR.selectedXRBtn.material.opacity = nowVR.selectedXRBtn.currentOpacity;
              nowVR.selectedXRBtn = null;
            }
          }
          clockAdd = 0;
        } else if (clockAdd > 100) {
          clockAdd = 0;
        }
        clockAdd += clock.getDelta();
      }); // 初始化vr
      this.vr = nowVR;
      console.log(this.vr);

      this.$emit('init', nowVR);

      /* -------------------控制器显示 end--------------------*/
      this.setVideo();
      // this.setVideo('https://vravo-1258661974.cos.accelerate.myqcloud.com/tank~1920i.mp4');
      // 'https://vravo-1258661974.cos.accelerate.myqcloud.com/itsuki%20akira_posing~1920i.mp4'
      // setTimeout(() => {
      //   this.setVideo('https://vravo-1258661974.cos.accelerate.myqcloud.com/itsuki%20akira_posing~1920i.mp4');
      // }, 30000);
    },
    setVideo () {
      const { vr, src, playCfg } = this;
      if (vr) {
        if (src) {
          vr.play(src, vr.resType.sliceVideo, playCfg); // 初始化视频
          this.$emit('setVideo');
        }
      } else {
        this.init();
      }
    },
    // 沉浸式控制器射线添加
    buildController (data) {

      let geometry, material;

      switch (data.targetRayMode) {

        case 'tracked-pointer':

          geometry = new THREE.BufferGeometry();
          geometry.setAttribute('position', new THREE.Float32BufferAttribute([0, 0, 0, 0, 0, -1], 3));
          geometry.setAttribute('color', new THREE.Float32BufferAttribute([0.5, 0.5, 0.5, 0, 0, 0], 3));

          material = new THREE.LineBasicMaterial({ vertexColors: true, blending: THREE.AdditiveBlending });

          return new THREE.Line(geometry, material);

        case 'gaze':

          geometry = new THREE.RingGeometry(0.02, 0.04, 32).translate(0, 0, -1);
          material = new THREE.MeshBasicMaterial({ opacity: 0.5, transparent: true });
          return new THREE.Mesh(geometry, material);

      }

    },
    operationCallback (el, name, isAtive) {
      this.$emit('operation', { el, name, isAtive });
    },
    destroy () {
      const { vr } = this;
      vr && vr.destroy();
    },
    onVideoEvent (name) {
      this.$emit(name);
    }
  }
};
</script>

<style scoped lang="less">
  #vrVideoComp{
    width: 100%;
    height: 100%;
    color: #1B1C1D;
    .loading{
      width: 2rem;
      height: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      background-color: rgba(0, 0, 0, 0.2);
      z-index: 999999;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
    }
  }
</style>