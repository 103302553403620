import Vue from 'vue';
import VueRouter from 'vue-router';

// hack router push callback
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) {return originalPush.call(this, location, onResolve, onReject);}
  return originalPush.call(this, location).catch((err) => err);
};
// 重写路由
const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace (location) {
  return originalReplace.call(this, location).catch((err) => err);
};


Vue.use(VueRouter);

/**
 * meta 可选参数
 * backHide 是否显示右边返回按钮，默认false
 * showTabbar 是否显示底部tabbar, 默认false
 * title 标题
 * logo  logo图标, 默认 true
 * keepAlive 是否需要缓存页面，默认false
 */

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import('@/views/tabBar/home'),
    meta: {
      showfixedNav: true,
      topHide: true,
      showTabbar: true,
      title: '最初のページ'
    }
  },
  {
    path: '/category',
    name: 'Category',
    component: () => import('@/views/tabBar/category'),
    meta: {
      showfixedNav: true,
      topHide: true,
      showTabbar: true,
      title: 'カテゴリ'
      // keepAlive: true
    }
  },
  {
    path: '/personal',
    name: 'Personal',
    component: () => import('@/views/tabBar/personal'),
    meta: {
      topHide: true,
      showTabbar: true,
      title: 'センター'
    }
  },
  {
    path: '/user/login/:type([1,2])',
    name: 'Login',
    component: () => import('@/views/user/login/_type'),
    meta: {
      topHide: true,
      title: 'ログイン',
      logo: true
    }
  },
  {
    path: '/user/register/:type([1,2])',
    name: 'Register',
    component: () => import('@/views/user/register/_type'),
    meta: {
      topHide: true,
      title: '登録する',
      logo: true
    }
  },
  {
    path: '/user/pwChange/:type(forgot|modify)',
    name: 'PwChange',
    component: () => import('@/views/user/pwChange/_type'),
    meta: {
      title: '',
      logo: true
    }
  },
  {
    path: '/user/subscribe/success',
    name: 'SubscribePage',
    component: () => import('@/views/user/subscribe/success'),
    meta: {
      title: '',
      backHide: false,
      topHide: true,
      logo: true
    }
  },
  {
    path: '/search',
    name: 'Search',
    component: () => import('@/views/detail/search'),
    meta: {
      topHide: true,
      title: '検索',
      keepAlive: true
    }
  },
  {
    path: '/detail',
    name: 'Detail',
    component: () => import('@/views/detail/detail'),
    meta: {
      topHide: true,
      bg: '#1B1C1D',
      title: '詳細'
      // title: ''
    }
  },
  {
    path: '/video',
    name: 'Video',
    component: () => import('@/views/detail/video'),
    meta: {
      topHide: true,
      title: '詳細',
      logo: true
      // iphoneXHide: true
    }
  },
  {
    path: '/videoBuy',
    name: 'VideoBuy',
    component: () => import('@/views/detail/videoBuy'),
    meta: {
      title: '動画を購入',
      requireAuth: true,
      logo: true
    }
  },
  {
    path: '/videoBuyResult/:type(success|faild)',
    name: 'VideoBuyResult',
    component: () => import('@/views/detail/videoBuyResult'),
    meta: {
      title: '注文のお支払い',
      requireAuth: true,
      backHide: false,
      topHide: true
    }
  },
  {
    path: '/coupons/index',
    name: 'CouponsIndex',
    component: () => import('@/views/coupons/index'),
    meta: {
      title: 'キャンペーン実施中',
      bg: '#FC9745',
      requireAuth: true
    }
  },
  {
    path: '/activity/index',
    name: 'ActivityIndex',
    component: () => import('@/views/activity/index'),
    meta: {
      title: '',
      requireAuth: true,
      logo: true
    }
  },
  {
    path: '/personal/collectList',
    name: 'CollectList',
    component: () => import('@/views/personal/collectList'),
    meta: {
      title: 'お気に入り',
      requireAuth: true
    }
  },
  {
    path: '/personal/purchaseList',
    name: 'PurchaseList',
    component: () => import('@/views/personal/purchaseList'),
    meta: {
      topHide: true,
      title: '購入済み',
      requireAuth: true
    }
  },
  {
    path: '/personal/couponList',
    name: 'CouponList',
    component: () => import('@/views/personal/couponList'),
    meta: {
      title: 'マイクーポン',
      requireAuth: true
    }
  },
  {
    path: '/personal/member/index',
    name: 'MemberIndex',
    component: () => import('@/views/personal/member/index'),
    meta: {
      topHide: true,
      title: '会員センター'
    }
  },
  {
    path: '/personal/member/orderConfirm',
    name: 'MemberOrderConfirm',
    component: () => import('@/views/personal/member/orderConfirm'),
    meta: {
      title: '',
      bg: '#1C1D1F',
      requireAuth: true,
      logo: true
    }
  },
  {
    path: '/personal/member/orderList',
    name: 'MemberOrderList',
    component: () => import('@/views/personal/member/orderList'),
    meta: {
      topHide: true,
      bg: '#1C1D1F',
      title: '購入履歴',
      requireAuth: true
    }
  },
  {
    path: '/personal/member/success',
    name: 'MemberSuccess',
    component: () => import('@/views/personal/member/success'),
    meta: {
      topHide: true,
      title: '',
      bg: '#32303A',
      requireAuth: true
    }
  },
  {
    path: '/personal/news/index',
    name: 'NewsIndex',
    component: () => import('@/views/personal/news/index'),
    meta: {
      title: 'メッセージ',
      requireAuth: true
    }
  },
  {
    path: '/personal/news/list/:type([1,2,3])',
    name: 'NewsList',
    component: () => import('@/views/personal/news/list/_type'),
    meta: {
      title: '',
      requireAuth: true
    }
  },
  {
    path: '/personal/news/detail',
    name: 'NewsDetail',
    component: () => import('@/views/personal/news/detail'),
    meta: {
      title: ''
    }
  },
  {
    path: '/personal/historyList',
    name: 'HistoryList',
    component: () => import('@/views/personal/historyList'),
    meta: {
      topHide: true,
      title: '再生履歴',
      requireAuth: true
    }
  },
  {
    path: '/personal/feedback',
    name: 'Feedback',
    component: () => import('@/views/personal/feedback'),
    meta: {
      title: 'お問い合わせ',
      requireAuth: true
    }
  },
  {
    path: '/personal/setting/index',
    name: 'SettingIndex',
    component: () => import('@/views/personal/setting/index'),
    meta: {
      title: '設定',
      requireAuth: true
    }
  },
  {
    path: '/personal/setting/edit',
    name: 'SettingEdit',
    component: () => import('@/views/personal/setting/edit'),
    meta: {
      title: '',
      requireAuth: true
    }
  },
  {
    path: '/personal/baseInfo/index',
    name: 'BaseInfoIndex',
    component: () => import('@/views/personal/baseInfoIndex'),
    meta: {
      title: '個人情報',
      requireAuth: true
    }
  },
  {
    path: '/public/success/:type([\\d])',
    name: 'Success',
    component: () => import('@/views/publicView/success/_type'),
    meta: {
      title: '',
      logo: true
    }
  },
  {
    path: '/public/agreement/:type([\\d])',
    name: 'Agreement',
    component: () => import('@/views/publicView/agreement/_type'),
    meta: {
      title: '',
      logo: true
      // requireAuth: true
    }
  },
  {
    path: '/testVideo',
    name: 'TestVideo',
    component: () => import('@/views/detail/testVideo'),
    meta: {
      topHide: true,
      title: '詳細',
      iphoneXHide: true
    }
  },
  {
    path: '/testVideoOld',
    name: 'TestVideoOld',
    component: () => import('@/views/detail/testVideoOld'),
    meta: {
      topHide: true,
      title: '詳細'
    }
  },
  {
    path: '/videoOld2',
    name: 'VideoOld2',
    component: () => import('@/views/detail/videoOld2'),
    meta: {
      topHide: true,
      title: '詳細'
    }
  },
  {
    path: '/delIndex',
    name: 'DelIndex',
    component: () => import('@/views/activity/delIndex'),
    meta: {
      title: 'イベントは終了しました'
    }
  },
  {
    path: '/personal/successPay',
    name: 'SuccessPay',
    component: () => import('@/views/personal/successPay'),
    meta: {
      title: ''
    }
  },
  {
    path: '/pc',
    name: 'Pc',
    component: () => import('@/views/personal/pc'),
    meta: {
      title: '',
      bg: '#fff',
      topHide: true,
      logo: true
    }
  }
];

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router;
