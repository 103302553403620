<template>
  <div id="topNavComp">
    <router-link class="link" :to="{name:'BaseInfoIndex'}">
      <van-image
        round
        class="avatar"
        fit="cover"
        :src="personInfo.avatarTemp || require('@/assets/images/avatarDefault_01.png') "
      />
      <template v-if="personInfo.jurisdiction">
        <i :class="'icon icon-vip ' + getAvatarIcon"></i>
      </template>
    </router-link>
    <router-link :to="{name: 'Search'}" class="search-box">
      <i class="icon icon-search"></i>
      <div class="txt">動画を検索</div>
    </router-link>
    <div class="right">
      <div @click="$router.push({name:'NewsIndex'})" class="icon icon-news">
        <div :class="{dot:newMessage}"></div>
      </div>
      <!-- <router-link :to="{name: 'NewsIndex'}" class="icon icon-news"></router-link> -->
      <router-link :to="{name: 'HistoryList'}" class="icon icon-clock"></router-link>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'TopNav',
  props: {
    newMessage: {
      type: Boolean,
      default: false
    }
  },
  components: {},
  data () {
    return {};
  },
  created () {
  },
  mounted () {
  },
  computed: {
    ...mapGetters(['personInfo']),
    getAvatarIcon () {
      const { personInfo: { jurisdiction } } = this;
      let className = '';
      if (jurisdiction.includes(4)) {
        className = 'icon-vip-1';
      } else if (jurisdiction.includes(1) && jurisdiction.includes(3)) {
        className = 'icon-vip-2';
      } else if (jurisdiction.includes(3)) {
        className = 'icon-vip-3';
      }
      return className;
    }
  },
  watch: {},
  methods: {}
};
</script>

<style scoped lang="less">
#topNavComp {
  position: relative;
  z-index: 10;
  padding: 24px 28px;

  &,
  .search-box,
  .right {
    display: flex;
    align-items: center;
  }

  .avatar {
    flex-shrink: 0;
    width: 64px;
    height: 64px;
  }

  .link {
    position: relative;

    .icon-vip {
      position: absolute;
      right: 0;
      bottom: 0;
      z-index: 1;
      width: 32px;
      height: 28px;

      &.icon-vip-1 {
        background-image: url("~@/assets/images/icon_vip_03.png");
      }

      &.icon-vip-2 {
        background-image: url("~@/assets/images/icon_vip_05.png");
      }

      &.icon-vip-3 {
        background-image: url("~@/assets/images/icon_vip_06.png");
      }
    }
  }

  .search-box {
    display: flex;
    flex: 1;
    align-items: center;
    height: 64px;
    padding: 12px 28px;
    margin-right: 28px;
    margin-left: 28px;
    background-color: rgba(64, 64, 64, 0.9);
    border-radius: 32px;

    .icon-search {
      width: 32px;
      height: 32px;
      margin-right: 16px;
      background-image: url("~@/assets/images/icon_search_01.png");
    }

    .txt {
      font-size: 26px;
      line-height: 34px;
      color: rgba(255, 255, 255, 0.3);
    }
  }

  .right {
    flex-shrink: 0;

    .icon {
      width: 48px;
      height: 48px;

      &:not(:first-child) {
        margin-left: 6px;
      }

      &.icon-news {
        background-image: url("~@/assets/images/icon_news_01.png");
        position: relative;
        .dot{
          width: 10px;
          height: 10px;
          background: #ED544F;
          border-radius: 50%;
          position: absolute;
          right: 2px;
          top: 6px;
        }
      }

      &.icon-clock {
        background-image: url("~@/assets/images/icon_clock_01.png");
      }
    }
  }
}
</style>