<template>
  <div class="getCode"
       :class="{'sendCode':!disabled&&sendCode}"
  ><span>{{showText}}</span></div>
</template>

<script>
export default {
  name: 'GetCode',
  props: {
    obj: Object,
    sendCode: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      showText: '',
      second: '',
      disabled: false,
      info: {
        second: 60,
        startText: '検証コードを送信',
        endText: '再取得'
      }
    };
  },
  mounted () {
    let { info, obj } = this;
    info = Object.assign(info, obj);
    this.second = info.second;
    this.showText = info.startText;
  },
  methods: {
    toSend (callback) {
      let { second, disabled, info } = this;
      if (disabled) {
        return;
      }
      this.disabled = true;
      callback && callback(this.disabled);
      this.showText = second + 's';
      let timer = setInterval(() => {
        second--;
        this.showText = second + 's';
        if (second === 0) {
          clearInterval(timer);
          this.second = info.second;
          this.showText = info.endText;
          this.disabled = false;
          callback && callback(this.disabled);
        }
      }, 1000);
    }
  }
};
</script>

<style scoped lang="less">
  .getCode {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 170px;
    font-size: 26px;
    color: #c1c1c1;

    &.sendCode {
      color: #ff803e;
    }
  }
</style>
