<template>
  <div id="emptyComp">
    <div class="icon icon-empty" :class="[emptyImg]"></div>
    <div class="empty-tip" v-if="tip">
      {{tip}}
    </div>
    <slot name="btn">
      <button
        type="button"
        class="empty-btn"
        v-if="btnName"
        @click="$emit('onNext')"
      >{{btnName}}</button>
    </slot>
  </div>
</template>

<script>
export default {
  name: 'Empty',
  props: {
    emptyImg: {
      type: String,
      default: 'icon-empty-1'
    },
    tip: {
      type: String,
      default: '内容はまだない'
    },
    btnName: {
      type: String,
      default: ''
    }
  },
  components: {},
  data () {
    return {

    };
  },
  created () {
  },
  mounted () {

  },
  watch: {},
  methods: {}
};
</script>

<style scoped lang="less">
  #emptyComp {
    width: 100%;
    height: 100%;
    padding-bottom: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9;
    background-color: @primaryBg;
    .icon-empty {
      width: 200px;
      height: 200px;
      margin-bottom: 22px;
      &.icon-empty-1{
        background-image: url("~@/assets/images/empty_01.png");
      }
      &.icon-empty-2{
        background-image: url("~@/assets/images/empty_02.png");
      }
      &.icon-empty-3{
        background-image: url("~@/assets/images/empty_03.png");
      }
    }
    .empty-tip {
      font-size: 26px;
      line-height: 34px;
      color: #FFFFFF;
      opacity: 0.6;
    }
    .empty-btn {
      display: inline-block;
      width: 272px;
      height: 74px;
      margin-top: 80px;
      font-size: 28px;
      line-height: 74px;
      color: #fff;
      text-align: center;
      background: #bd8a43;
      border-radius: 38px;
    }
  }
</style>