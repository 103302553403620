import router from './router';
import store from './store';
import request from './assets/js/request';
import utils from './assets/js/utils';
// 登录拦截
router.beforeEach(async (to, from, next) => {
  // 如果是手机或者linux跳h5
  if (to.name !== 'Pc' && utils.isPc()) {
    return next({
      name: 'Pc'
    });
  }
  const { meta, name, query } = to;
  const { ticket } = query;
  const { requireAuth } = meta;
  let personInfo = { isLogin: false };
  let nextParmas = null;
  if (meta.keepAlive) {
    store.dispatch('setKeepAliveInclude', name);
  }
  let ticketRes;


  if (ticket) {
    ticketRes = await request.post('game/entrance', { ticket }, { customError: true });
    nextParmas = {
      name: 'Home'
    };
    if (ticketRes.code === 200) {
      const ticketData = ticketRes.data;
      switch (ticketData.type) {
        case 1:
          utils.storage.set('loginToken', ticketData || {});
          // 已存在用户直接跳首页
          break;
        case 2:
          nextParmas = {
            name: 'Register',
            params: {
              type: 2
            },
            query: {
              email: ticketData.email,
              gidType: ticketData.type
            }
          };
          // 不存在用户有邮箱跳设置密码页面
          break;
        case 3:
          // 不存在用户没邮箱跳邮箱绑定注册页面
          nextParmas = {
            name: 'Register',
            params: {
              type: 1,
              gidType: ticketData.type
            }
          };
          break;
      }

    } else {
      utils.toast(ticketRes.msg);
    }
  }
  if (ticketRes) {
    store.dispatch('setTicker', ticketRes.data);
  }

  /* 判断有没有token */
  if (utils.getToken()) {
    await request.get('mine/information', { customError: true }).then((res) => {
      alert('mine/information：成功');
      personInfo = res.data || {};
      personInfo.isLogin = true;
    }).catch(() => {
      alert(utils.getToken());
      utils.storage.remove('loginToken');
      utils.storage.remove('email');
    });
  }

  store.dispatch('setPersonInfo', personInfo);

  // 判断是否有登录权限
  if (requireAuth && !personInfo.isLogin) {
    return utils.toast({
      message: 'ログインへ',
      onClose: () => {
        next({
          name: 'Login', // 未登录则跳转至login页面
          params: {
            type: 1
          },
          query: { redirect: to.fullPath || '' } // 登陆成功后回到当前页面，这里传值给login页面，to.fullPath为当前点击的页面
        });
      }
    });
  }


  if (nextParmas) {
    next(nextParmas);
  } else {
    next();
  }
});

router.afterEach((to, from) => {

  /* 头部标题 */
  document.title = to.meta.title ? `Vravo-${to.meta.title}` : 'Vravo';
});