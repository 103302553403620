<template>
    <div id="listItemComp" :class="[style[detail.type]]">
      <div class="thumb">
        <van-image class="img" :src="detail.coverAcrossTemp" fit="cover"></van-image>
        <div class="mask">
          <div class="item item-1">
            <div class="left">
              <slot name="tag">
                <i class="icon icon-tag"></i>
              </slot>
            </div>
            <div class="right" @click.stop.prevent="showFun">
              <slot name="more">
                <i class="icon icon-more"></i>
              </slot>
            </div>
          </div>
          <div class="item item-2">
            <div class="left">
              <slot name="views">
                <i class="icon icon-eyes"></i>
                <div class="name">{{ detail.sum }}</div>
              </slot>
            </div>
            <div class="right">
              <slot name="time">{{ detail.time }}</slot>
            </div>
          </div>
          <slot name="play">
            <i class="icon icon-play"></i>
          </slot>
        </div>
      </div>
      <slot name="content">
        <div class="content">
          <div class="name">{{detail.name}}</div>
        </div>
      </slot>
    </div>
</template>

<script>
export default {
  name: 'listItemComp',
  components: {},
  props: {
    detail: {
      type: Object,
      default () {
        return {};
      }
    }
  },
  data () {
    return {
      style: {
        3: 'style-1', // 4k
        2: 'style-2' // vip
      }
    };
  },
  created () {
  },
  mounted () {

  },
  watch: {},
  methods: {
    showFun () {
      console.log(this.detail.id);
      this.$emit('showFun', this.detail.id);
    }
  }
};
</script>

<style scoped lang="less">
#listItemComp {
  .thumb {
    position: relative;
    height: 340px;

    &,
    .mask {
      overflow: hidden;
      border-radius: 16px;
    }

    .img {
      width: 100%;
      height: 100%;
    }

    .mask {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.2);

      .item {
        position: absolute;
        left: 0;
        justify-content: space-between;
        width: 100%;

        &,
        .left,
        .right {
          display: flex;
          align-items: center;
        }

        &.item-1 {
          top: 0;
          padding: 8px 6px 8px 0;

          .icon-tag {
            width: 64px;
            height: 28px;
          }

          .icon-more {
            width: 56px;
            height: 48px;
            background-image: url("~@/assets/images/icon_dot_01.png");
          }
        }

        &.item-2 {
          bottom: 0;
          padding: 14px 16px;
          font-size: 22px;
          line-height: 30px;

          .left {
            .icon-eyes {
              width: 29px;
              height: 28px;
              margin-right: 8px;
              background-image: url("~@/assets/images/icon_eyesOpen_03.png");
            }
          }
        }
      }

      .icon-play {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 80px;
        height: 80px;
        background-image: url("~@/assets/images/icon_arrowRight_03_.png");
        transform: translate(-50%, -50%);
      }
    }
  }

  .content {
    margin-top: 18px;
    font-size: 26px;
    line-height: 40px;

    .name {
      height: 40px;
    }
  }

  &.style-1 {
    .thumb {
      .mask {
        .item {
          .icon-tag {
            background-image: url("~@/assets/images/icon_tag_05.png");
          }
        }
      }
    }
  }

  &.style-2 {
    .thumb {
      .mask {
        .item {
          .icon-tag {
            background-image: url("~@/assets/images/icon_tag_07.png");
          }
        }
      }
    }
  }
}
</style>