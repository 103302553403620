<template>
  <div id="Uploader">
    <div class="c-list">
      <div class="bg-cover c-list-item c-add-btn" v-if="list.length < max || max === 0" >
        <van-uploader :before-read="beforeRead" :after-read="afterRead" multiple>
          <slot name="uploaderIcon">
            <div class="wrap">
              <i class="icon icon-uploader"></i>
              <div class="txt">写真を追加</div>
            </div>
          </slot>

        </van-uploader>
      </div>
      <div class="c-list-item bg-cover"
           v-for="(item, index) of list"
           :key="index"
           @click="handleClick(index)"
           :style="{backgroundImage: `url(${item.src})`}">
      </div>
    </div>

    <van-action-sheet
      v-model="show"
      :actions="actions"
      cancel-text="取消"
      @select="onSelect"
    />
  </div>
</template>

<script>
import { ActionSheet, Uploader, ImagePreview } from 'vant';
export default {
  name: 'Uploader',
  data () {
    return {
      tip: '',
      show: false,
      selectIndex: 0,
      paths: []
    };
  },
  props: {
    list: {
      type: Array,
      default: function () {
        return [];
      }
    },
    index: { // 选填
      type: Number,
      default: 0
    },
    max: {
      // 选填,上传图片的最大张数
      type: Number,
      default: 4
    },
    actions: {
      type: Array,
      default: function () {
        return [
          { name: '大きな画像で確認' },
          { name: '画像を削除' }
        ];
      }
    }
  },
  components: {
    'van-action-sheet': ActionSheet,
    'van-uploader': Uploader
  },
  methods: {
    beforeRead (file) {
      this.$emit('beforeRead', file);
      return true;
    },
    afterRead (file) {
      console.log(file);
      let data = (file instanceof Array ? (this.max > 0 ? file.slice(0, this.max - this.list.length) : file) : [file]);
      this.$emit('afterRead', data);
    },
    handleClick (index) {
      this.show = true;
      this.selectIndex = index;
    },
    onSelect (e) {
      const { selectIndex, list } = this;
      this.show = false;
      if (e.name === '大きな画像で確認') {
        ImagePreview({
          images: list.map((item) => item.src),
          startPosition: selectIndex
        });
      } else if (e.name === '画像を削除') {
        list.splice(selectIndex, 1);
      }
    }
  }

};
</script>

<style lang="less" scoped>
  #Uploader {
    overflow: hidden;

    .c-list {
      /* margin-top: 20/756rem; */
      display: flex;
      flex-wrap: wrap;
      margin-right: -16px;
      margin-left: -16px;

      .c-list-item {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 148px;
        height: 136px;
        margin: 0 16px 20px;
        overflow: hidden;
        background-color: @blackBg353537;
        border-radius: 8px;
        opacity: 1;

        /deep/
        .van-uploader {
          &,
          .van-uploader__wrapper,
          .van-uploader__upload,
          .van-uploader__input-wrapper,
          .wrap {
            width: 100%;
            height: 100%;
          }

          .van-uploader__wrapper {
            .van-uploader__upload {
              margin: 0;
              background: none;
            }
          }

          .wrap {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .icon-uploader {
              width: 74px;
              height: 74px;
              margin-bottom: 6px;
              background-image: url("~@/assets/images/icon_photo_01.png");
            }

            .txt {
              font-size: 24px;
              line-height: 32px;
              color: #fff;
              opacity: 0.3;
            }
          }
        }

        .c-image {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
          overflow: hidden;
          background-color: #fafafa;

          img {
            width: auto;
            height: 100%;
          }
        }

        .icon {
          width: 23px;
          height: 23px;
        }

        .c-add-btn {
          background-color: #f5f5f5;
          border: solid 1px #dcdcdc;
        }
      }

      p {
        font-size: 24px;
        color: #999;
      }
    }
  }
</style>
