import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    personInfo: {},
    ticker: {},
    keepAliveInclude: [],
    appScrollTop: 0,
    area: null,
    originalArea: {},
    htmlWidth: 0 // 网页宽
  },
  getters: {
    personInfo (state) {
      return state.personInfo;
    },
    ticker (state) {
      return state.ticker;
    },
    keepAliveInclude (state) {
      return state.keepAliveInclude;
    },
    area (state) {
      return state.area;
    },
    originalArea (state) {
      return state.originalArea;
    },
    appScrollTop (state) {
      return state.appScrollTop;
    },
    htmlWidth: function (state) {
      return state.htmlWidth;
    }
  },
  mutations: {
    setPersonInfo (state, params) {
      state.personInfo = params;
    },
    setTicker (state, params) {
      state.ticker = params;
    },
    setKeepAliveInclude (state, params) {
      if (!state.keepAliveInclude.includes(params)) {
        state.keepAliveInclude.push(params);
      }
    },
    removeKeepAliveInclude (state, params) {
      const index = state.keepAliveInclude.indexOf(params);
      if (index > -1) {
        state.keepAliveInclude.splice(index, 1);
      }
    },
    setArea (state, params) {
      state.area = params;
    },
    setOriginalArea (state, params) {
      state.originalArea = params;
    },
    setAppScrollTop: function (state, params) {
      state.appScrollTop = params;
    },
    setHtmlWidth: function (state, params) {
      state.htmlWidth = params;
    }
  },
  actions: {
    setPersonInfo ({ commit }, params) {
      commit('setPersonInfo', params);
    },
    setTicker ({ commit }, params) {
      commit('setTicker', params);
    },
    setKeepAliveInclude ({ commit }, params) {
      commit('setKeepAliveInclude', params);
    },
    removeKeepAliveInclude ({ commit }, params) {
      commit('removeKeepAliveInclude', params);
    },

    /* 设置省市区列表数据 */
    setArea ({ commit }, params) {
      commit('setArea', params);
    },
    setOriginalArea ({ commit }, params) {
      commit('setOriginalArea', params);
    },
    setAppScrollTop: function ({ commit }, params) {
      commit('setAppScrollTop', params);
    },
    setHtmlWidth: function ({ commit }, params) {
      commit('setHtmlWidth', params);
    }
  },
  modules: {
  }
});
