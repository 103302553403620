<template>
    <div id="topSearchComp">
      <div class="search-box">
        <i class="icon icon-search"></i>
        <input
          :value="value"
          class="input"
          type="text"
          @input="onChange"
          @focus="isfocus = true"
          @blur="isfocus = false"
          :placeholder="placeholder"
        >
        <van-icon v-if="isfocus&&value" @touchstart="onClear" @mousedown="onClear" class="icon-clear" name="clear" />
      </div>
      <div class="btn" @click="$emit('click-right', value)">{{btnText}}</div>
    </div>
</template>

<script>
export default {
  name: 'TopSearch',
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: {
      type: [Number, String],
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    btnText: {
      type: String,
      default: ''
    }
  },
  components: {},
  data () {
    return {
      isfocus: false,
      timer: null
    };
  },
  created () {
  },
  mounted () {

  },
  watch: {},
  methods: {
    onChange (e) {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.$emit('change', e.target.value);
      }, 600);
    },
    onClear () {
      this.$emit('change', '');
    }
  }
};
</script>

<style scoped lang="less">
  #topSearchComp {
    padding: 24px 56px 24px 28px;
    position: relative;
    z-index: 10;
    &,
    .search-box {
      display: flex;
      align-items: center;
    }
    .search-box {
      display: flex;
      flex: 1;
      align-items: center;
      height: 64px;
      padding-left: 28px;
      background-color: rgba(64, 64, 64, 0.9);
      border-radius: 32px;
      position: relative;
      .icon-search {
        flex-shrink: 0;
        width: 32px;
        height: 32px;
        background-image: url("~@/assets/images/icon_search_01.png");
      }
      .input {
        flex: 1;
        font-size: 26px;
        padding-right: 88px;
        padding-left: 28px;
        height: 100%;
      }
      .icon-clear {
        font-size: 32px;
        color: rgba(255,255,255,0.2);
        padding: 10px;
        position: absolute;
        top: 50%;
        right: 18px;
        transform: translateY(-50%);
        z-index: 2;
      }
    }
    .btn {
      cursor: pointer;
      font-size: 24px;
      line-height: 32px;
      color: #FFFFFF;
      opacity: 0.8;
      margin-left: 20px;
    }
  }
</style>