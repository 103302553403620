<template>
  <div id="SubmitBar" class="iphoneX">
    <div class="wrap iphoneX" :style="{bottom: info.bottom}">
      <div class="left">
        <slot name="left">
          <van-checkbox v-model="info.checked"></van-checkbox>
        </slot>
      </div>
      <div class="right">
        <div class="info">
          <slot name="info">
            <div class="item item-1">
              <span>合计：</span>
              <span class="price color-FF3E3E">¥{{$utils.formatNumber(info.price)}}</span>
            </div>
            <div class="item item-2" v-if="info.tip">{{info.tip}}</div>
          </slot>
        </div>
        <van-button class="btn" color="#BD8A43" :loading="asyncSubmitLoading" @click="onSubmit">
          {{info.btnText}}
        </van-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SubmitBar',
  props: {
    obj: Object
  },
  data () {
    return {
      info: {
        price: 0,
        tip: '(不含运费)',
        btnText: '提交订单',
        bottom: 0
      },
      asyncSubmitLoading: false
    };
  },
  watch: {
    'obj.price': function (v) {
      this.info.price = v;
    },
    'obj.btnText': function (v) {
      this.info.btnText = v;
    },
    'info.checked': function (v) {
      this.$emit('onChange', {
        checked: v
      });
    }
  },
  mounted () {
    let { info, obj } = this;
    this.info = Object.assign(info, obj);
  },
  methods: {
    onSubmit () {
      let { info } = this;
      if (info.asyncSubmit) {
        this.asyncSubmitLoading = true;
      }
      this.$emit('onSubmit');
    },
    closeAsyncSubmitLoading () {
      this.asyncSubmitLoading = false;
    }
  }
};
</script>

<style scoped lang="less">
#SubmitBar {
  padding-top: 98px;

  .wrap {
    position: fixed;
    left: 0;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    min-height: 98px;
    padding-right: 30px;
    padding-left: 30px;
    background-color: #fff;

    .right {
      display: flex;
      align-items: center;

      .info {
        margin-right: 30px;
        text-align: right;

        .item-1 {
          font-size: 28px;
          line-height: 40px;
          color: #656060;
        }

        .item-2 {
          font-size: 24px;
          line-height: 34px;
          color: #d2cccc;
        }
      }

      .btn {
        width: 196px;
        height: 74px;
        font-size: 28px;
        border-radius: 38px;
      }
    }
  }
}
</style>
