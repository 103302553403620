<template>
  <div class="popup-container">
    <!-- 分享弹窗跟稍后观看弹窗开始 -->
    <van-popup
      class="popup popup-allFun"
      v-model="updatedShow"
      position="bottom"
      :style="{ height: '35%' }"
      @click-overlay="show"
    >
      <div class="content">
        <template v-if="detail.whetherBuy===1 || (personInfo.jurisdiction && personInfo.jurisdiction.includes(detail.type + 1)) || detail.type === 1">
          <div class="allFun">
            <div class="fun"
            @click="later()"
            v-if="
            (
              detail.whetherBuy===1 ||
              (personInfo.jurisdiction && personInfo.jurisdiction.includes(detail.type + 1)) ||
              detail.type === 1
            ) && !isRecommend"
            >
              <template v-if="detail.whetherLater===2">
                <i class="icon icon-addLater"></i>
                <span>後で見るに追加</span>
              </template>
              <template v-else>
                <i class="icon icon-delLater"></i>
                <span>再生リストから削除</span>
              </template>
            </div>
            <div class="fun" v-if="detail.putaway!==2" @click="showShare = !showShare">
              <i class="icon icon-share"></i>
              <span>共有する</span>
            </div>
            <template v-if="isRecommend && detail.putaway===2">
              <div>
                操作項目がありません
              </div>
            </template>
          </div>
        </template>
        <template v-else>
          <div>
            操作項目がありません
          </div>
        </template>
        <div class="btn">
          <van-button @click="upDateFun()">キャンセル</van-button>
        </div>
      </div>
    </van-popup>
    <!-- 分享弹窗跟稍后观看弹窗结束 -->
    <!-- 分享弹窗开始 -->
    <van-popup
      class="popup popup-share"
      v-model="showShare"
      position="bottom"
      :style="{ height: '35%' }"
    >
      <div class="content">
        <div class="title">シェア</div>
        <div class="share">
          <button class="share-icon" v-for="(item, index) in shareList" :key="index" @click="shareViedo(item)">
            <van-image
              :src="item.img"
              fit="cover"
              class="img"
            ></van-image>
            <div>{{ item.text }}</div>
          </button>
        </div>
        <div class="btn">
          <van-button @click="showShare=false">キャンセル</van-button>
        </div>
      </div>
    </van-popup>
    <!-- 分享弹窗结束 -->

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import * as clipboard from 'clipboard-polyfill/text';
export default {
  name: 'PopupShow',
  props: {
    showFun: {
      type: Boolean,
      default: false
    },
    detail: {
      type: Object,
      default () {return {};}
    },
    isRecommend: { //
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      updatedShow: false,
      showShare: false
    };
  },
  watch: {
    'showFun': {
      handler (newVal, oldVal) {
        this.updatedShow = newVal;
      }
    }
  },
  mounted () {
  },
  computed: {
    ...mapGetters(['personInfo']),
    shareList () {
      const { detail: { name } } = this;
      return [
        { img: require('@/assets/images/icon_share_01.png'), text: 'Twitter', href: `https://twitter.com/intent/tweet?text=Vravo-${name}&url=` },
        { img: require('@/assets/images/icon_share_02.png'), text: 'Facebook', href: 'http://www.facebook.com/sharer.php?u=' },
        { img: require('@/assets/images/icon_share_03.png'), text: 'LINE', href: 'https://lineit.line.me/share/ui?url=' },
        { img: require('@/assets/images/icon_share_04.png'), text: 'リンクコピー', href: 'copy' }
      ];
    }
  },
  methods: {

    /* 添加或移除稍后观看视频 */
    later () {
      const { $utils, detail: { id } } = this;
      this.$http.get('video/operationLater', {
        params: {
          videoId: id
        }
      }).then((res) => {
        const data = res.data;
        data === 1 ? $utils.successToast('追加成功') : $utils.successToast('追加をキャンセル');
        this.upDateFun();
        this.$emit('later');
      });
    },

    /* 分享视频 */
    async shareViedo (item) {
      const { detail: { id }, $utils } = this;
      const href = window.location.origin + '/#/detail?id=' + id;
      switch (item.href) {
        case 'copy':
          clipboard.writeText(href).then(async () => {
            await this.setShareVideoCount();
            $utils.successToast('コピー済み!');
          }, () => {
            $utils.failToast('コピー失敗!');
          });
          break;
        default:
          window.open(
            item.href + encodeURIComponent(href),
            '_blank',
            'toolbar=yes, location=yes, directories=no, status=no, menubar=yes, scrollbars=yes, resizable=no, copyhistory=yes, width=600, height=450,top=100,left=350'
          );
          await this.setShareVideoCount();
          break;
      }
      this.showShare = false;
      this.$emit('share');

    },
    async setShareVideoCount () {
      const { detail: { id } } = this;
      await this.$http.get('video/shareVideo', {
        params: {
          videoId: id
        },
        showLoading: true
      });
    },

    /* 点击遮罩层隐藏弹框 */
    show () {
      this.upDateFun();
    },
    upDateFun () {
      this.$emit('upDate', false);
    }

  }
};
</script>

<style lang="less" scoped>
  .popup-container {
    .popup-share {
      padding: 44px 38px 28px;

      .content {
        .title {
          text-align: center;
        }

        .share {
          display: flex;
          justify-content: space-between;
          margin-top: 46px;

          .share-icon {
            .img {
              box-sizing: border-box;
              display: inline-block;
              width: 96px;
              height: 96px;
              border: 1px dashed #fff;
            }

            div {
              width: 96px;
              font-size: 24px;
              line-height: 32px;
              color: #fff;
              text-align: center;
              opacity: 0.8;
              .text-overflow();
            }
          }
        }

        .btn {
          margin-top: 68px;

          /deep/ button {
            width: 100%;
            font-size: 26px;
            line-height: 34px;
            color: #fff;
            background: #393a3b;
            border: none;
            border-radius: 16px;
            opacity: 0.8;
          }
        }
      }
    }

    .popup-allFun {
      padding: 44px 38px 28px;

      .content {
        .allFun {
          .fun {
            display: flex;
            align-items: center;
            height: 112px;
            font-size: 26px;
            line-height: 34px;
            line-height: 112px;
            color: #fff;
            border-bottom: 0.5px solid rgba(255, 255, 255, 0.05);
            opacity: 1;

            .icon {
              width: 48px;
              height: 48px;

              &.icon-addLater {
                background-image: url("~@/assets/images/icon_addLater_01.png");
              }

              &.icon-delLater {
                background-image: url("~@/assets/images/icon_delLater_01.png");
              }

              &.icon-share {
                background-image: url("~@/assets/images/icon_share_05.png");
              }
            }

            span {
              margin-left: 24px;
            }
          }
        }

        .btn {
          margin-top: 68px;

          /deep/ button {
            width: 100%;
            font-size: 26px;
            line-height: 34px;
            color: #fff;
            background: #393a3b;
            border: none;
            border-radius: 16px;
            opacity: 0.8;
          }
        }
      }
    }
  }
</style>