<template>
  <div id="Dialog">
    <van-popup v-model="show" class="custom-dialog">
      <div class="dialog-container">
        <div class="dialog-message">
          <p class="title">{{title}}</p>
          <p class="message">{{content}}</p>
        </div>
        <div class="dialog-bottom">
          <button class="cancel" v-if="asyncCancelLoading"><van-loading /></button>
          <button class="cancel" :style="{color: cancelColor}" v-else @click="cancel">{{cancelText}}</button>
          <button class="confirm" v-if="asyncConfirmLoading"><van-loading /></button>
          <button class="confirm" :style="{color: confirmColor}" v-else @click="confirm">{{confirmText}}</button>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { Popup, Loading } from 'vant';

export default {
  name: 'Dialog',
  mounted () {
  },
  data () {
    return {
      asyncCancelLoading: false,
      asyncConfirmLoading: false
    };
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    title: String,
    content: String,
    'cancelText': {
      type: String,
      default: '決定'
    },
    'confirmText': {
      type: String,
      default: '後で'
    },
    'cancelColor': {
      type: String,
      default: '#1a1a1a'
    },
    'confirmColor': {
      type: String,
      default: '#ffa800'
    },
    'asyncCancel': {
      type: Boolean,
      default: false
    },
    'asyncConfirm': {
      type: Boolean,
      default: false
    }
  },
  watch: {
    show (v) {
      if (!v) {
        this.asyncConfirmLoading = this.asyncConfirmLoading = v;
      }
    }
  },
  components: {
    'van-popup': Popup,
    'van-loading': Loading
  },
  methods: {
    cancel () {
      if (this.asyncCancel) {
        this.asyncCancelLoading = true;
      }
      this.$emit('cancel');
    },
    confirm () {
      if (this.asyncConfirm) {
        this.asyncConfirmLoading = true;
      }
      this.$emit('confirm');
    }
  }
};
</script>

<style lang="less" scoped>
  #Dialog {
    .custom-dialog {
      &.van-popup {
        overflow: hidden;
        border-radius: 10px;
      }

      .dialog-container {
        width: 580px;

        /* height: 1000px; */
        background: none;
        border-radius: 3px;
      }

      .dialog-message {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 36px;

        .title {
          font-size: 42px;
          font-weight: bold;
          color: #1a1a1a;
        }

        .message {
          margin-top: 30px;
          font-size: 24px;
          color: #1a1a1a;

          p {
            margin: 0 10px;
            font-size: 30px;
            font-weight: bold;
            color: #ffa800;
          }
        }
      }

      .dialog-bottom {
        z-index: 1;
        display: flex;
        align-items: center;
        width: 100%;
        height: 103px;
        margin-top: 60px;
        background-color: #fff;
        border-top: 1px solid #e6e6e6;

        button {
          display: flex;
          flex: 1;
          align-items: center;
          justify-content: center;
          height: 103px;
          font-size: 30px;
          line-height: 103px;
          text-align: center;
          background: none;

          &.cancel {
            border-right: 1px solid #e6e6e6;
            border-bottom-left-radius: 10px;
          }

          &.confirm {
            border-bottom-right-radius: 10px;
          }

          &:active {
            background: #eee;
          }
        }
      }
    }
  }
</style>
