<template>
  <div id="Swipe">
    <van-swipe
      :autoplay="autoplay"
      width="100%"
      :indicator-color="indicatorColor"
      @change="onChange"
      :style="{height:height/75+'rem'}"

    >
      <van-swipe-item v-for="(item, index) in images" :key="index" @click="onClick()">
        <van-image
          lazy-load
          class="img"
          fit="cover"
          :src="item"
        />
      </van-swipe-item>
      <template #indicator>
        <div class="custom-indicator">
          <div class="item" :class="{active:current===index}" v-for="(item, index) in images" :key="index"></div>
        </div>
      </template>

    </van-swipe>
  </div>
</template>

<script>
import { ImagePreview } from 'vant';
export default {
  name: 'Swipe',
  components: {},
  props: {
    detail: {
      type: Array,
      default: () => []
    },
    imgUrlKey: {
      type: String,
      default: 'url'
    },
    linkTypeKey: {
      type: String,
      default: ''
    },
    linkKey: {
      type: String,
      default: ''
    },
    isPreview: {
      type: Boolean,
      default: false
    },
    height: {
      type: Number,
      default: 358
    },
    indicatorColor: {
      type: String,
      default: '#BD8A43'
    },
    autoplay: {
      type: Number,
      default: 5000
    }
  },
  data () {
    return {
      current: 0,
      images: []
    };
  },
  created () {
  },
  mounted () {
    this.images = this.detail.map((item) => item[this.imgUrlKey]);
  },
  watch: {},
  methods: {
    onChange (index) {
      this.current = index;
    },
    onClick (index) {
      let { current, images, isPreview } = this;
      if (isPreview) {
        ImagePreview({
          images,
          startPosition: current
        });
      }
    }
  }
};
</script>

<style scoped  lang="less">
  #Swipe {
    .img {
      width: 100%;
      height: 100%;
      background-color: #fafafa;
    }

    .custom-indicator {
      position: absolute;
      bottom: 20px;
      left: 50%;
      display: flex;
      transform: translateX(-50%);

      .item {
        width: 12px;
        height: 12px;
        background-color: rgba(0, 0, 0, 0.16);
        border-radius: 100%;

        &:not(:last-child) {
          margin-right: 10px;
        }

        &.active {
          background-color: #bd8a43;
        }
      }
    }
  }
</style>
