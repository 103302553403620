<template>
    <div id="editInfoPopComp">
      <van-popup
        class="popup"
        v-model="visible"
        position="bottom"
        @opened="onOpened"
      >

        <div class="top-nav-bar">
          <van-nav-bar :title="detail.title"  @click-left="visible = false" @click-right="onSave">
            <van-icon class="back-arrow" name="arrow-left"  slot="left" />
            <div class="right" slot="right">
              <button class="btn" type="button">保存</button>
            </div>
          </van-nav-bar>
        </div>
        <slot name="content">
          <div class="content">
            <input
              class="input"
              v-model="detail.value"
              type="text"
              :placeholder="detail.placeholder||'入力してください'"
              ref="input"
              @focus="isfocus = true"
              @blur="isfocus = false"
            >
            <van-icon v-if="isfocus&&detail.value" @touchstart="onClear" @mousedown="onClear" class="icon-clear" name="clear" />
          </div>
        </slot>
      </van-popup>
    </div>
</template>

<script>
import { NavBar } from 'vant';
export default {
  name: 'EditInfoPop',
  model: {
    prop: 'visible',
    event: 'change'
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  components: {
    'van-nav-bar': NavBar
  },
  data () {
    return {
      detail: {},
      isfocus: false,
      timer: null
    };
  },
  created () {
  },
  mounted () {

  },
  watch: {
    visible (newVal) {
      this.$emit('change', newVal);
    }
  },
  methods: {
    init (params) {
      this.detail = params || {};
      this.$emit('change', true);
    },
    onClear () {
      this.detail.value = '';
    },
    onOpened () {
      this.$refs.input.focus();
    },
    onSave () {
      console.log(this.detail);
      this.$emit('save', this.detail);
    }
  }
};
</script>

<style scoped lang="less">
  #editInfoPopComp {
    position: relative;
    z-index: 999999;
    .popup{
      height: 100%;
      background-color: @primaryBg;
      .top-nav-bar {
        .right{
          .btn {
            height: 48px;
            font-size: 24px;
            color: #2D2E2E;
            background-color: @primaryBlueBg;
            padding-right: 8px;
            padding-left: 8px;
            border-radius: 8px;
          }
        }
      }
      .content{
        position: relative;
        .input {
          width: 100%;
          height: 96px;
          font-size: 26px;
          background-color: #2D2E2E;
          padding-left: 28px;
          padding-right: 88px;
        }
        .icon-clear {
          font-size: 32px;
          color: rgba(255,255,255,0.2);
          padding: 10px;
          position: absolute;
          top: 50%;
          right: 18px;
          transform: translateY(-50%);
          z-index: 2;
        }
      }
    }
  }
</style>