import Vue from 'vue';
import utils from './utils';

Vue.filter('NumberFormat', function (value) {
  if (!value) {
    return '0';
  }
  const intPartFormat = value.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,'); // 将整数部分逢三一断
  return intPartFormat;
});


/**
 * 保留小数位
 * @params v; 数值
 * @params l; 小数位数，默认1位
 * @return value; 返回一个字符串
 */
Vue.filter('toFixed', (v = 0, l = 2) => utils.toFixed(v, l));

/**
 * 格式化数字二，如果数值超过万就单位显示
 * @param v 数字
 * @param lenght 小数后几位，默认2
 * @param threshold 阙值，默认10000
 * @param unit 单位 默认万
 * @returns
 */
Vue.filter('numSetUnit', (v, length = 2, threshold = 10000, unit = '万') => {
  v = Number(v || 0);

  let value = 0;
  if (v >= threshold) {
    v = v / 10000;
    value = utils.toFixed(v, length) + unit;
  } else {
    value = utils.toFixed(v, length);
  }

  return value;
});


/**
 * 时间格式化
 * @params time; 任意时间值
 * @params l; 时间格式
 * @return fmt; 返回一个已经格式化的字符串
 */
Vue.filter('formatDate', (time, fmt = 'yyyy-MM-dd hh:mm:ss') => {
  try {
    time = time || '';
    if (typeof time === 'string') {
      time = time.replace(/-/g, '/');
    }
    if (!time) {
      return '';
    }
    const date = new Date(time);
    if (/(y+)/.test(fmt)) {
      fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
    }
    const o = {
      'M+': date.getMonth() + 1,
      'd+': date.getDate(),
      'h+': date.getHours(),
      'm+': date.getMinutes(),
      's+': date.getSeconds()
    };
    for (const k in o) {
      if (new RegExp(`(${k})`).test(fmt)) {
        const str = o[k] + '';
        fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : padLeftZero(str));
      }
    }
    return fmt;
  } catch (e) {
    return '';
  }

  function padLeftZero (str) {
    return ('00' + str).substr(str.length);
  }
});

/**
 * 给不足两位的数值补零
 * @params v; 数值
 * @return str; 返回一个以及格式化的字符串
 */
Vue.filter('repairZero', (v) => {
  const value = v.toString();

  return value[1] ? value : 0 + value;
});

/**
 * 字符串去空格
 * @params str; 字符串
 * @return trimStr; 返回一个去掉两边空格的字符串
 */
Vue.filter('trim', (str) => {
  let trimStr = '';

  trimStr = str.replace(/\s/g, '');

  return trimStr;
});

/**
 * 加密字符串
 * @params v; 数值或者字符串
 * @params l; 中间位数
 * @params s; 替换符号
 * @return str; 返回一个以及加密的字符串
 */
Vue.filter('encryptionStr', (v, l = 5, s = '*') => {
  try {
    v = v.toString().split('');
    const start = Math.floor(v.length / 2) - Math.floor(5 / 2);

    for (let i = start; i < (start + 5); i++) {
      v[i] = s;
    }

    return v.join('');
  } catch (err) {
    throw new Error(err);
  }
});

