import axios from 'axios';
import store from '@/store';
import utils from './utils';
import { VueAxios } from './axios';

// 创建 axios 实例
const request = axios.create({
  // API 请求的默认前缀
  baseURL: process.env.VUE_APP_API_BASE_URL,
  customError: false,
  timeout: 60000 // 请求超时时间
});

// 异常拦截处理器
const errorHandler = (error) => {
  if (error.response) {
    const data = error.response.data;
    // 从 localstorage 获取 token
    const token = utils.getToken();
    if (error.response.status === 403) {
      utils.failToast(data.message);
    }
    if (error.response.status === 401 && !(data.result && data.result.isLogin)) {
      utils.failToast(data.message);
      if (token) {
        store.dispatch('Logout').then(() => {
          setTimeout(() => {
            window.location.reload();
          }, 1500);
        });
      }
    }
  }
  return Promise.reject(error);
};

// request interceptor
request.interceptors.request.use((config) => {
  const token =  utils.getToken();
  config.showLoading = config.showLoading || false;
  config.loadingText = config.loadingText || 'ローディング...';
  config.hideErrorMessage = config.hideErrorMessage || false;
  config.customError = config.customError || false;
  config.success = config.success || null;
  if (token) {
    config.headers.Authtoken = token;
  }

  /* 是否开启loading */
  if (config.showLoading) {
    utils.loadingToast(config.loadingText);
  }

  config.headers['Cache-Control'] = 'no-cache';

  return config;
}, errorHandler);

// response interceptor
request.interceptors.response.use((res) => {
  const data = res.data || {};
  const { hideErrorMessage, customError, showLoading, success } = res.config;

  if (showLoading) {
    utils.clearToast();
  }

  return new Promise((resolve, reject) => {
    if (data.code === 200) {
      if (success) {
        utils.successToast(success);
      }

      resolve(data);
    } else if (customError) {
      const error = {
        data,
        response: { config: res.config }
      };
      
      reject(error);
    } else if (!hideErrorMessage && data.msg) {
      utils.failToast(data.msg);
    } else {
      resolve(data);
    }
  });
}, errorHandler);

const installer = {
  vm: {},
  install (Vue) {
    Vue.use(VueAxios, request);
  }
};

export default request;

export {
  installer as VueAxios,
  request as axios
};
